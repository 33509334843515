/* You can add global styles to this file, and also import other style files */

@import '@ctrl/ngx-emoji-mart/picker';

@font-face {
  font-family: MainFont;
  src: url(assets/fonts/Lato-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: MainFont;
  src: url(assets/fonts/Lato-Bold.ttf);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: MainFont;
  src: url(assets/fonts/Lato-Italic.ttf);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: apptitle;
  src: url(assets/fonts/newtitle.ttf) format('truetype');
  font-display: swap;
}

* {
  font-family:
    MainFont,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
}

html {
  /*min-height: -webkit-fill-available;*/
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  background-color: var(--bkg-color);
  overflow: hidden;
}
body {
  /*touch-action: none;*/
  background-color: var(--bkg-color);
}

/* custom scroll bar */
::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}
::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #838383;
}
/* -- end -- */

@keyframes simple-loading-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes simple-loading-fading {
  0%,
  100% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.95;
  }
}
/* -- end -- */

.subtle-button {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  text-align: inherit;
}

/* Mat tabs */
.mdc-form-field > label {
  color: var(--text-color-black);
}

.mdc-tab-indicator {
  /*display: none !important;*/
}

.mdc-tab-indicator__content--underline {
  border-color: var(--blue-hightlight-color) !important;
}

.mdc-tab-indicator__content--underline {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-top-width: 4px !important;
}

.mat-mdc-tab-label-container {
  border-bottom: var(--usual-divider-thickness) solid var(--divider-color) !important;
}

.mdc-tab__text-label {
  margin-bottom: 10px !important;
}

.link-wrapper:hover {
  color: var(--text-color-black) !important;
  background-color: inherit !important;
}

.mat-mdc-tab {
  letter-spacing: inherit !important;
}

.mat-mdc-tab {
  background-color: var(--bkg-color) !important;
}

/************/

.cdk-overlay-pane {
  margin-right: 7px;
  margin-left: 7px;
}

.zero-width {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

/* tooltips */
.tooltiptext {
  visibility: hidden;
  font-size: 12px;
  background-color: var(--tooltip-bkg);
  color: var(--text-color-white);
  text-align: center;
  padding: 0 4px 0 4px;
  border-radius: var(--usual-border-radius);
  position: absolute;
  z-index: 10;
}
@media (hover: hover) and (pointer: fine) {
  .tooltipwrapper:hover .tooltiptext {
    transition-delay: 0.7s;
    visibility: visible;
  }
}
/* -- end -- */

/* standard action buttons, such as the menu/options button */
/*@media (hover: hover) and (pointer: fine) {*/
.action-button-wrapper:hover {
  opacity: 0.8;
  cursor: pointer;
}
/*}*/
.standard-action-button {
  width: 35px;
  height: 35px;
  padding: 10px;
}
@media only screen and (max-width: 1200px) {
  .standard-action-button {
    width: 25px;
    height: 25px;
    padding: 5px;
  }
}
@media only screen and (max-width: 1000px) {
  .standard-action-button {
    width: 20px;
    height: 20px;
    padding: 3px;
  }
}
@media only screen and (max-width: 700px) {
  .standard-action-button {
    width: 15px;
    height: 15px;
    padding: 1px;
  }
}
@media only screen and (max-width: 500px) {
  .standard-action-button {
    width: 14px;
    height: 14px;
    padding: 1px;
  }
}
/* -- end -- */

/* standard menu button */
.menu-button {
  opacity: 0.8;
}
/*@media (hover: hover) and (pointer: fine) {*/
.menu-button:hover .menu-icon {
  /*background-color: rgba(0, 0, 0, 0.095);*/
  border-radius: 50%;
  opacity: 1;
}
/*}*/
/* -- end -- */

.verified-badge-chat {
  height: 13px;
  width: 13px;
  margin-left: 4px;
  margin-top: 2px;
}
.verified-badge-big {
  height: 22px;
  width: 22px;
  margin-left: 5px;
  cursor: pointer;
}

.seeMoreButton {
  font-weight: bold;
  font-size: 16px;
  color: var(--text-color-light-10);
  margin-top: 3px;
}
@media (hover: hover) and (pointer: fine) {
  .seeMoreButton:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.count-showing-div-wrapper {
  font-size: 15px;
  font-weight: inherit;
  box-sizing: border-box;
  -webkit-box-pack: center;
}
.count-showing-div-text {
  border: 1px solid var(--bkg-color);
  background-clip: padding-box;
  word-wrap: break-word;
  border-radius: 0.75rem;
  padding: 0 0.3rem;
  text-align: center;
  color: var(--white);
  font-weight: normal;
  min-width: 1.4rem;
  height: 1.4rem;
  font-size: 0.9rem;
  line-height: 1.3124rem;
  /*background: #4b4b4b;*/
  background-color: var(--bkg-blue-number-badge);
}

@media only screen and (max-width: 500px) {
  .count-showing-div-text {
    line-height: 1.1875rem;
    min-width: 1.3rem;
    height: 1.3rem;
    font-size: 0.7812rem;
    line-height: 1.187rem;
  }
}

.beta-hint {
  padding: 2px 5px;
  font-weight: bold;
  background-color: var(--red-bkg-button-color);
  color: var(--colored-btn-txt-color);
  font-size: 14px;
  margin: 0 5px;
  border-radius: var(--usual-border-radius);
}
@media only screen and (max-width: 500px) {
  .beta-hint {
    padding: 1px 3px;
    font-size: 13px;
  }
}

p {
  margin: 0;
}
h1 {
  margin: 0;
}
h2 {
  margin: 0;
}
h3 {
  margin: 0;
}
h4 {
  margin: 0;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0;
}

h1.page-title {
  font-size: 20px;
  color: var(--text-color-black);
  font-weight: 500;
  margin: 0;
}
@media only screen and (max-width: 600px) {
  h1.page-title {
    font-size: 18px;
  }
}

.standard-options-menu {
  width: 500px;
}

.standard-options-menu {
  width: 500px;
}

.white-link-highlighting {
  color: var(--white-link-highlighting-color);
}
@media (hover: hover) and (pointer: fine) {
  .white-link-highlighting:hover {
    color: var(--white-link-highlighting-color-hover);
  }
}

/* option buttons */
.standard-options-dialog-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.standard-options-dialog-item:not(:first-child) {
  border-top: 0.5px solid var(--divider-color-light-10);
}
/*@media (hover: hover) and (pointer: fine) {*/
.standard-options-dialog-item:hover {
  background-color: var(--bkg-color-black-transparent-04);
}
/*}*/
.options-img {
  height: 15px;
  width: 15px;
  filter: brightness(0);
}
.options-img-wrap {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
}
.options-text {
  font-size: 15px;
  margin-left: 15px;
  margin-right: 15px;
  color: var(--text-color-black);
}

/* options buttons, smaller but with image */
/* Used ONLY for chat-message-options */
.standard-options-dialog-item-type3 {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: var(--text-color);
}
.options-img-type3 {
  height: 15px;
  width: 15px;
  filter: brightness(0);
}
.standard-options-dialog-item-type3:not(:first-child) {
  border-top: 0.5px solid var(--divider-color-light-10);
}
/*@media (hover: hover) and (pointer: fine) {*/
.standard-options-dialog-item-type3:hover {
  background-color: var(--bkg-color-black-transparent-04);
}
/*}*/
/*@media only screen and (max-width: 600px) {*/
.standard-options-dialog-item-type3:hover {
  background-color: inherit;
}
/*}*/
/* --- */

.media-emoji-picker {
  z-index: 500;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 55px;
  border-radius: 10px;
  background-color: var(--emoji-picker-bkg-color);
  box-shadow: 0px 0px 30px var(--bkg-emoji-picker);
}

.emoji-mart-bar:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .media-emoji-picker {
    width: 95%;
  }
}

@media only screen and (max-width: 500px) {
  .media-emoji-picker {
    width: 100%;
    position: inherit;
    bottom: 0;
    left: 0;
    margin: 0;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--emoji-picker-bkg-color);
    box-shadow: none;
    border-bottom: 1px solid var(--divider-color);
  }
  .emoji-mart {
    border: none;
    max-width: 100%;
    min-width: 100%;
    width: 100% !important;
  }
}

.virtual-keyboard-panelclass {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.virtual-keyboard-panelclass-margin-bottom {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 500px) {
  .virtual-keyboard-panelclass-margin-bottom {
    margin-bottom: 50px !important;
  }
}

.radio {
  display: block;
  cursor: pointer;
  user-select: none;
  text-align: left;
}

.radio + .radio {
  margin-top: 12px;
}

.radio input {
  display: none;
}

.radio input + span {
  display: inline-block;
  position: relative;
  padding-left: 30px;
}

.radio input + span:before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.radio input + span:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #222;
  position: absolute;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input:checked + span:after {
  opacity: 1;
  transform: scale(1, 1);
}

.usual-button {
  cursor: pointer;
  padding: 4px 20px;
  border-radius: var(--usual-border-radius);
  font-weight: bolder;
  font-size: 16px;
  text-align: center;
  color: var(--text-color);
}
.red-button {
  background-color: var(
    --red-bkg-button-color
  ) !important; /* !important should be removed by better css structure */
  color: var(--colored-btn-txt-color) !important;
}
/*@media (hover: hover) and (pointer: fine) {*/
.red-button:hover {
  background-color: var(--red-bkg-button-color-hover);
}
/*}*/

.profile-image {
  border-radius: 50%;
  border: 1px solid var(--profile-image-border-color);
  cursor: pointer;
  object-fit: contain;
  flex-shrink: 0;
}

.profile-image-2 {
  border-radius: 10px;
  border: 1px solid var(--profile-image-border-color);
  cursor: pointer;
  object-fit: contain;
  flex-shrink: 0;
}

/*@media (hover: hover) and (pointer: fine) {*/
.profile-image:hover {
  filter: brightness(0.85);
}
/*}*/

.full-screen-height {
  height: var(--full-height);
}

.full-screen-height-respecting-main-footer {
  height: var(--full-height);
}
@media only screen and (max-width: 600px) {
  .full-screen-height-minus-main-footer {
    height: var(--full-height-without-main-footer);
  }
}

/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------ */

@media only screen and (max-width: 700px) {
  .tooltiptext {
    display: none;
  }

  .verified-badge-chat {
    height: 10px;
    width: 10px;
    margin-left: 3px;
  }
  .verified-badge-big {
    height: 18px;
    width: 18px;
    margin-left: 3px;
  }
  .seeMoreButton {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .standard-options-dialog-item {
    padding: 8px 13px;
  }
  .tooltiptext {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
  }

  .options-text {
    font-size: 14px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 500px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    border: 0px solid transparent;
  }
  .seeMoreButton {
    font-size: 14px;
  }
}
/* --- */

.small-loading-cycle {
  border: 3px solid var(--loading-cycle-color-grey);
  border-radius: 100px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  height: 15px;
  width: 15px;
  animation-name: simple-loading-loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
  margin-right: 5px;
  display: block;
}
@keyframes simple-loading-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes simple-loading-fading {
  0%,
  100% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.95;
  }
}

.css-doodle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.width-100 {
  width: 100%;
}

.verified-badge {
  height: 18px;
  width: 18px;
  margin-left: 1px;
}
@media only screen and (max-width: 800px) {
  .verified-badge {
    height: 16px;
    width: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .verified-badge {
    height: 15px;
    width: 15px;
    margin-left: 0px;
  }
}

.bottom-sheet-dialog-container-hidden {
  transform: translateY(100%);
}

.bottom-sheet-dialog-container-appear-anim {
  animation-name: container-hidden-appear;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  border-radius: var(--usual-border-radius) var(--usual-border-radius) 0 0 !important;
}
@keyframes container-hidden-appear {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.pop-up-fade-in-dialog-appear-anim {
  animation-name: pop-up-fade-in-dialog-appear-anim;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}
@keyframes pop-up-fade-in-dialog-appear-anim {
  0% {
    opacity: 0;
    transform: scale(0.97);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: #ffffff00 !important;
}

/* mat dialog styling */
.mat-mdc-menu-panel {
  background-color: #ffffff00 !important; /*var(--bkg-dialog-color) !important;*/
}

/* Sets ALL dialogs in the project to have a max width */
.mat-mdc-dialog-surface.mdc-dialog__surface {
  overflow: hidden;
  max-width: 700px !important;
}

/* remove that on mobile ends */
@media only screen and (max-width: 600px) {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    max-width: 100vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .bottom-sheet-dialog {
    position: fixed !important;
    bottom: 0 !important;
    max-width: 100vw !important;
    width: 100vw;
    border-radius: var(--usual-border-radius) var(--usual-border-radius) 0 0 !important;
    padding-bottom: var(--safe-bottom);
  }

  .bottom-sheet-dialog-type2 {
    border-radius: var(--usual-border-radius) var(--usual-border-radius) 0 0 !important;
    padding-bottom: var(--safe-bottom);
    padding-top: 5px;
    background-color: var(--bkg-color);
    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

.bottom-sheet-matmenu-wrapper {
  border-radius: var(--usual-border-radius) !important;
  background-color: var(--white-bkg-color) !important;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .bottom-sheet-matmenu-wrapper {
    border-radius: var(--usual-border-radius) var(--usual-border-radius) 0 0 !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 100vw !important;
    background-color: var(--transparent-color) !important;
  }
}

/* use panel class with this class to remove that above maxwith for a dialog */
.no-max-width .mat-mdc-dialog-surface.mdc-dialog__surface {
  max-width: none !important;
}

/* 
 * (mm1):
 * Has 8px or so top bottom padding normally. 
 * But since we made the BG transparent, it messes up the look.    
 */
.mat-mdc-menu-content {
  padding: 0 !important;
}
/* same reason as above (mm1) */
.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-mdc-dialog-content {
  background-color: var(--bkg-color);
}

.black-backdrop {
  background-color: var(--bkg-color-dark-12);
}
.black-backdrop-light {
  background-color: var(--bkg-color-dark-30);
}

.chat-dialog .mat-dialog-container {
  padding: 0;
  background: var(--bkg-color-dark-20);
}

.bdrop {
  background-color: var(--bkg-color-dark-15);
  backdrop-filter: blur(5px);
}

.cdk-global-overlay-wrapper {
  background-color: var(--dialog-back-drop-color);
}

.message-options-menu {
  background-color: transparent;
}

.bottom-border-divider {
  border-bottom: 1px solid var(--divider-color);
}

.no-interaction {
  pointer-events: none; /* Disables all pointer events */
  touch-action: none; /* Disables touch-based scrolling */
}

/* fixed header (fh) */
.fh-page-wrapper {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  background-color: var(--bkg-color);
  padding-bottom: var(--safe-bottom);
}

.fh-header {
  width: 100%;
  padding-top: 10px;
  position: relative;
  background-color: var(--bkg-color);
  z-index: 200;
}

.fh-main {
  overflow-y: overlay;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.dark-icon {
  filter: brightness(0) invert(var(--dark-icon-invert-number));
}
.light-icon {
  filter: brightness(0) invert(1);
}

/* Disable selection on ALL elements */
/* But only on mobile, on desktop it would not be a good UX */
@media only screen and (max-width: 500px) {
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
/* Allow it only on elements with this class */
.allow-user-selection {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.simple_divider {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 8px;
  color: var(--text-color-light-15);
}

/* Ad stuff */
.ad-action-button {
  text-align: center;
  padding: 7px;
  border-radius: var(--usual-border-radius);
  background-color: var(--blue-bkg-button-color);
  margin-top: 10px;
  color: var(--colored-btn-txt-color);
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  width: 100%;
}

.learn-more-ad-icon {
  height: 9px;
  width: 9px;
  margin-left: 5px;
  margin-bottom: 1px;
}

/*@media (hover: hover) and (pointer: fine) {*/
.ad-action-button:hover {
  background-color: var(--blue-bkg-button-color-hover);
}
/*}*/

.red-ad-action-button {
  background-color: var(--red-bkg-button-color);
}
/*@media (hover: hover) and (pointer: fine) {*/
.red-ad-action-button:hover {
  background-color: var(--red-bkg-button-color-hover);
}
/*}*/

/****************************************************************/
/****************************************************************/
/****************************************************************/

@media only screen and (max-width: 600px) {
  .ad-action-button {
    padding: 5px;
    font-size: 14px;
  }
}
/* -- */

/* Mat bottom sheets */
.mat-bottom-sheet-container {
  padding: 0 !important;
  padding-bottom: var(--safe-bottom) !important;
}

.mat-bottom-sheet-container {
  transition:
    transform 100ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}
/*******************/

.transparent-background {
  background: transparent;
}

/* Test wise */
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

/* vid player thumbnail */
.video-thumbnail-wrapper {
  position: relative;
}
.video-hint-play-icon {
  width: 35px;
  height: 35px;
  margin-top: 1px;
  margin-left: 1px;
}
.video-hint-play-icon-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 65px;
  height: 65px;
  padding: 15px;
  border-radius: 50%;
  background-color: #000000a8;
}

.usual-divider-coloring {
  color: var(--divider-color);
  background-color: var(--divider-color);
  opacity: 0.8;
  margin: 0;
}

.usual-border-radius {
  border-radius: var(--usual-border-radius);
}

.usual-grey-rounded-widget {
  border-radius: var(--usual-border-radius);
  background-color: var(--bkg-usual-widget-grey);
  overflow: hidden;
}

/* Remove underline from anchor especially */
.no-decor {
  text-decoration: none;
}

.overflow-scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
}

.respect-safe-area-top {
  padding-top: var(--safe-top);
}
.respect-safe-area-bottom {
  padding-bottom: var(--safe-bottom);
}
.respect-safe-area-right {
  padding-right: var(--safe-right);
}
.respect-safe-area-left {
  padding-left: var(--safe-left);
}

.clip-item-height {
  height: var(--clip-item-height);
}
@media only screen and (max-width: 600px) {
  .clip-item-height {
    height: calc(var(--clip-item-height-2) - var(--safe-bottom));
  }
}

.clips-text-shadow {
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
}

/******************************************************************************************
/*******************************************************************************************/
/*******************************************************************************************/
/*******************************************************************************************/
/*******************************************************************************************/

/* number defs */
:root {
  --clip-item-height: var(--full-height);
  --clip-item-height-2: var(--full-height-without-main-footer);

  --usual-divider-thickness: 0.7px;
  --usual-border-radius: 10px;

  --safe-top: env(safe-area-inset-top);
  --safe-bottom: env(safe-area-inset-bottom);
  --safe-left: env(safe-area-inset-left);
  --safe-right: env(safe-area-inset-right);

  --main-footer-nav-height: 53px;

  --full-height: 100vh;
  --full-height: 100dvh;
  --full-height-without-main-footer: calc(
    var(--full-height) - var(--main-footer-nav-height)
  );
}

/* Color definitions. Referenced from other stylesheets using var(...).
/* Used for moduralizing but also dark mode.
/* ALL color must be here.


/* Light theme */
body {
  --transparent-color: #ffffff00;

  --white: white;
  --black: rgb(0, 0, 0);
  --white-light-10: rgb(204, 204, 204);

  --msg-timestamp-color: rgb(221, 221, 221);
  --fullscreen-bkg-color: rgba(0, 0, 0, 0.8);

  --text-color-black: #000000;
  --text-color-very-dark: #0e0e0e;
  --text-color: #222;
  --text-color-light-5: rgb(41, 41, 41);
  --text-color-light-10: rgb(70, 70, 70);
  --text-color-light-15: rgb(105, 105, 105);

  /** do not use on white background for SEO (accessibility)!! */
  --text-color-light-20: rgb(139, 139, 139);
  --text-color-light-30: rgb(165, 165, 165);
  --text-color-light-50: rgb(192, 192, 192);
  --text-color-light-80: rgb(221, 221, 221);

  --text-color-white: #ffffff;

  --text-color-disabled-grey-button: rgb(192, 192, 192);

  --chatpage-username-text-color: #000;

  --bkg-usual-widget-grey: #f7f9f9;
  --bkg-usual-widget-grey-hover: #f1f1f1;

  --bkg-color-chatpage: rgb(61, 61, 61);
  --bkg-color-dark-5: rgba(0, 0, 0, 0.8);
  --bkg-color-dark-10: rgba(0, 0, 0, 0.7);
  --bkg-color-dark-12: rgba(0, 0, 0, 0.5);
  --bkg-color-dark-15: #00000096;
  --bkg-color-dark-20: #4d4d4d;
  --bkg-color-dark-30: rgba(0, 0, 0, 0.3);
  --bkg-color-dark-40: rgba(0, 0, 0, 0.1);
  --bkg-color-dark-50: rgba(0, 0, 0, 0.06);

  --bkg-color-dark-premium-payment-button: rgb(26, 26, 26);

  --bkg-color-new-msgs-hint: rgba(0, 0, 0, 0.8);

  --tooltip-bkg: rgba(0, 0, 0, 0.7);

  --dialog-back-drop-color: rgb(0, 0, 0, 0.2);

  --loading-cycle-color-grey: rgb(88, 88, 88);

  --bkg-color-black-transparent-04: rgba(0, 0, 0, 0.04);

  --profile-image-border-color: rgb(202, 202, 202);

  --bkg-color: #fff;
  --bkg-color-dim-light-10: rgb(238, 238, 238);
  --bkg-color-dim-light-15: rgb(236, 236, 236);
  --bkg-color-dim-light-20: rgb(223, 223, 223);
  --bkg-color-dim-light-30: rgb(211, 211, 211);
  --bkg-dialog-color: #fff;

  --bkg-color-inversed: #000;

  --mat-tab-header-active-ripple-color: var(--bkg-color) !important;

  --white-bkg-color: #fff;
  --white-bkg-color-but-lighter-in-darkmode: #fff;

  --bkg-color-dim-light-transp-10: rgba(240, 240, 240, 0.3);

  --grey-10: rgb(192, 192, 192);
  --grey-30: rgb(146, 146, 146);
  --grey-50: rgb(114, 114, 114);
  --grey-70: rgb(83, 83, 83);

  --donation-goal-bar-bg: rgb(114, 114, 114);

  --gif-picker-bg: rgb(255, 255, 255);

  --colored-btn-txt-color: white;

  --red-like-color: rgb(243, 0, 73);
  --red-like-color-for-text: rgb(
    226,
    0,
    68
  ); /* darker now for SEO (accessibility) */

  --red-bkg-button-color: rgb(
    231,
    0,
    69
  ); /* darker now for SEO (accessibility) */
  /* --red-bkg-button-color: rgb(243, 0, 73); */
  --red-bkg-button-color-hover: rgb(218, 0, 65);

  --red-bkg-highligh-color: rgb(243, 0, 73);

  --grey-bkg-button-color: rgb(235 235 235);
  --grey-bkg-button-color-hover: rgb(223, 223, 223);

  --blue-bkg-button-color: rgb(
    0,
    102,
    255
  ); /* darker now for SEO (accessibility) */
  /*--blue-bkg-button-color: rgb(0, 140, 255);*/
  --blue-bkg-button-color-hover: rgb(0, 130, 255);

  --blue-hightlight-color: rgb(0, 140, 255);

  --following-btn-hover-bkg-color: rgba(253, 201, 206, 0.37);
  --following-btn-hover-text-color: rgb(244, 33, 46);
  --following-btn-hover-border-color: rgba(255, 114, 114, 0.4);

  --divider-color: rgb(239, 239, 239);
  --divider-color-light-5: rgb(233, 233, 233);
  --divider-color-light-10: rgb(241, 241, 241);
  --divider-color-medium: rgb(218, 218, 218);
  --divider-color-medium-5: rgb(207, 207, 207);
  --divider-color-medium-10: rgb(192, 192, 192);
  --divider-color-dark: rgb(160, 160, 160);
  --divider-color-dark-hr-version: rgba(160, 160, 160, 0.2);

  --icon-hover-bkg: rgba(0, 0, 0, 0.055);

  --bkg-button-color-hover-light: rgb(238, 238, 238);

  --bkg-explore-sidebar-button-color-hover: rgb(228, 228, 228);

  --bkg-blue-number-badge: rgb(0, 104, 223); /* now darker for seo */
  /*--bkg-blue-number-badge: rgb(29, 155, 240);*/

  --bkg-red-number-badge: rgb(226, 0, 68); /* now darker for seo */
  /*--bkg-red-number-badge: rgb(243, 0, 73);*/

  --bkg-post-button: rgb(0, 0, 0);
  --bkg-post-button-hover: rgb(12, 12, 12);
  --post-button-shadow-color: #1a1a1a50;

  --dark-icon-invert-number: 0;

  --header-shadow-color: rgba(133, 133, 133, 0.16);
  --trending-box-shadow-color: #1a1a1a2f;
  --dialog-box-shadow-color: rgba(0, 0, 0, 0.3);
  --general-message-wrapper-shadow-color: rgba(0, 0, 0, 0.2);
  --box-shadow-color-dark-10: rgba(0, 0, 0, 0.8);
  --box-shadow-color-dark-20: rgba(0, 0, 0, 0.4);
  --box-shadow-color-dark-30: rgba(0, 0, 0, 0.2);

  --white-link-highlighting-color: white;
  --white-link-highlighting-color-hover: rgb(235, 235, 235);

  --bkg-emoji-picker: rgba(0, 0, 0, 1);

  --purple: purple;
  --purple-hover: rgb(105, 0, 105);

  --slider-before-color: white;
  --slider-bkg: #ccc;

  --input-type-a-bkg: #f8f8f8;
  --input-type-a-border-color: #dadada;

  --highlighted-message-bkg: rgba(24, 116, 255, 0.349);
  --reply-border-blue-color: rgb(61, 194, 255);
  --reply-msg-bkg-by-yourself: rgb(0, 35, 99);
  --reply-msg-bkg-by-yourself-hover: rgb(0, 26, 73);
  --reply-msg-bkg-by-yourself-border-color: rgb(0, 65, 185);
  --timestamp-on-image-bkg: rgba(27, 27, 27, 0.63);
  --message-by-yourself-bkg: rgb(58, 110, 255);
  --message-by-other-bkg: #fff;

  --comments-page-content-preview-border-blue: rgb(49, 173, 255);

  --loading-circle-blue-color: rgb(29, 155, 240);
  --loading-circle-bkg-color: rgba(29, 161, 242, 0.2);

  --emoji-picker-bkg-color: #fff;
}

/* Dark theme */
body.dark-theme {
  --text-color-black: #e7e7e7;
  --text-color-very-dark: #d8d8d8;
  --text-color: #cfcfcf;
  --text-color-light-5: rgb(177, 177, 177);
  --text-color-light-10: rgb(160, 160, 160);
  --text-color-light-15: rgb(125, 125, 125);
  --text-color-light-20: rgb(102, 102, 102);
  --text-color-light-30: rgb(85, 85, 85);
  --text-color-light-50: rgb(63, 63, 63);
  --text-color-light-80: rgb(50, 50, 50);
  --text-color-white: #1f1f1f;

  --text-color-disabled-grey-button: rgb(161, 161, 161);

  --chatpage-username-text-color: #a5a5a5;

  --bkg-usual-widget-grey: #252525;
  --bkg-usual-widget-grey-hover: rgb(49, 49, 49);

  --bkg-color-chatpage: rgb(37, 37, 37);
  --bkg-color-dark-5: rgba(179, 179, 179, 0.8);
  --bkg-color-dark-10: rgba(116, 116, 116, 0.7);
  --bkg-color-dark-12: rgba(102, 102, 102, 0.7);
  --bkg-color-dark-15: #66666696;
  --bkg-color-dark-20: #8a8a8a;
  --bkg-color-dark-30: rgba(99, 99, 99, 0.3);
  --bkg-color-dark-40: rgba(99, 99, 99, 0.15);
  --bkg-color-dark-50: rgba(99, 99, 99, 0.192);

  --bkg-dialog-color: #3d3d3d;

  --bkg-color-dark-premium-payment-button: rgb(235, 235, 235);

  --bkg-color-new-msgs-hint: rgba(104, 104, 104, 0.8);

  --tooltip-bkg: rgba(255, 255, 255, 0.8);

  --dialog-back-drop-color: rgba(99, 99, 99, 0.4);

  --bkg-color-black-transparent-04: rgba(78, 78, 78, 0.2);

  --loading-cycle-color-grey: rgb(189, 189, 189);

  --profile-image-border-color: rgb(124, 124, 124);

  --bkg-color: #000000;
  --bkg-color-dim-light-10: #1a1a1a;
  --bkg-color-dim-light-15: #202020;
  --bkg-color-dim-light-20: #2b2b2b;
  --bkg-color-dim-light-30: #3f3f3f;

  --bkg-color-inversed: #f0f0f0;

  --white-bkg-color: #0f0f0f;
  --white-bkg-color-but-lighter-in-darkmode: #252525;

  --bkg-color-dim-light-transp-10: rgba(68, 68, 68, 0.3);

  --grey-10: rgb(107, 107, 107);
  --grey-30: rgb(117, 117, 117);
  --grey-50: rgb(158, 158, 158);
  --grey-70: rgb(175, 175, 175);

  --donation-goal-bar-bg: rgb(100, 100, 100);

  --gif-picker-bg: #222;

  --grey-bkg-button-color: rgb(59, 59, 59);
  --grey-bkg-button-color-hover: rgb(70, 70, 70);

  --following-btn-hover-bkg-color: rgba(244, 33, 46, 0.1);
  --following-btn-hover-text-color: rgb(244, 33, 46);
  --following-btn-hover-border-color: rgb(103, 7, 15);

  --divider-color: rgb(70, 70, 70);
  --divider-color-light-5: rgb(70, 70, 70);
  --divider-color-light-10: rgb(61, 61, 61);
  --divider-color-medium: rgb(78, 78, 78);
  --divider-color-medium-5: rgb(100, 100, 100);
  --divider-color-medium-10: rgb(99, 99, 99);
  --divider-color-dark: rgb(121, 121, 121);
  --divider-color-dark-hr-version: rgba(121, 121, 121, 0.2);

  --icon-hover-bkg: rgba(255, 255, 255, 0.15);

  --bkg-button-color-hover-light: rgb(27, 27, 27);

  --bkg-explore-sidebar-button-color-hover: rgb(36, 36, 36);

  --bkg-post-button: rgb(255, 255, 255);
  --bkg-post-button-hover: rgb(233, 233, 233);
  --post-button-shadow-color: #6e6e6e50;
  --dialog-box-shadow-color: rgba(94, 94, 94, 0.3);
  --general-message-wrapper-shadow-color: rgba(94, 94, 94, 0.2);
  --box-shadow-color-dark-10: rgba(59, 59, 59, 0.8);
  --box-shadow-color-dark-20: rgba(59, 59, 59, 0.4);
  --box-shadow-color-dark-30: rgba(59, 59, 59, 0.2);

  --dark-icon-invert-number: 1;

  --header-shadow-color: rgba(114, 114, 114, 0.16);
  --trending-box-shadow-color: #4d4d4d2f;

  --bkg-emoji-picker: rgba(48, 48, 48, 0.7);

  --slider-before-color: rgb(22, 22, 22);
  --slider-bkg: #a5a5a5;

  --input-type-a-bkg: #2c2c2c;
  --input-type-a-border-color: #5a5a5a;

  --emoji-picker-bkg-color: #222;

  --message-by-other-bkg: #252525;
}

/*******************************************************************************************/
/*******************************************************************************************/
/*******************************************************************************************/
/*******************************************************************************************/
/*******************************************************************************************/
/*******************************************************************************************/
